.container{
    display: flex;
    flex-direction: column;
    width: max-content;
    height: 9rem;
    background: white;
    padding: 5rem;
    border-radius: 2rem;
    box-shadow: 0px 15px 15px 0.1px;
    margin-top: 8rem;
    margin-bottom: 5rem;
}
.info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -4rem;

}
.info img{
    width: 6rem;
    &:hover{
        transform: scale(1.1);
    }
}

.btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -2rem;
}

.btn{
    padding: 0.7rem 2rem;
    font-size: 18px;
    border-radius: 2rem;
    background: yellowgreen;
    border: none;
    color: #840000;
    &:hover{
        transform: scale(1.1);
        background: #679805;
        color: white;
        cursor: pointer;
    }
}