@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto+Slab&display=swap");

.loginContainer {
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
  padding: 5rem;
  background: white;
  font-family: "Roboto Slab", serif;
  border-radius: 2rem;
  box-shadow: 0px 15px 15px 0.1px;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.btn-l {
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  background: deepskyblue;
  color: white;
  font-size: 15px;
  font-weight: 700;
  border: none;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
    color: rgb(126, 18, 39);
  }
}

.input-p {
  padding: 0.3rem;
  width: 20rem;
  border: 2px solid deepskyblue;
  left: 1rem;
  position: relative;
  &::placeholder {
    color: rgb(58, 4, 158);
  }
}

.edit2 {
  position: relative;
  right: -5px;
}

.close{
  width: 18px;
  position: absolute;
  right: 26.5rem;
  top: 6rem;
  &:hover{
    cursor: pointer;
    transform: scale(1.1);
  }
}

.hide{
  display: none;
}
